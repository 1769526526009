// upload の管理本体
import { UploadProgressManager } from './UploadProgressManager';
import { FileUploadService } from './FileUploadService';
import { file } from 'jszip';


const skipFile = (fileItem) => {
  // Skip junk files from macOS
  if ((fileItem.filetype !== 'application/dicom')
      && (fileItem.name == '.DS_Store')) {
        console.debug('Skip upload', fileItem);
        return true;
  } else {
    // File should not be skipped
    return false;
  }
}

export class UploadService {
  maxWorkerNum = 5; // 最大同時ワーカー数（最大同時アップロード数）

  constructor({
    files,
    targetFolderUUID,
    targetOrgUUID,
    isOrgPerm,
    setProgress,
  }) {
    this.results = [];
    this.target_files = files;
    this.uploadProgressManager = new UploadProgressManager(
      this.target_files,
      setProgress
    );
    this.fileUploadService = new FileUploadService(
      targetFolderUUID,
      targetOrgUUID,
      isOrgPerm,
      this.uploadProgressManager
    );
  }

  async asyncAllUpload() {
    // target_files をすべてアップロードする
    const iterator = this.target_files.values();
    const workers = new Array(this.maxWorkerNum)
      .fill(iterator)
      .map(this.uploadWork.bind(this));
    await Promise.all(workers);

    console.log('asyncAllUpload', this.results.length, 'files done');
    return this.results;
  }

  async uploadWork(iterator) {
    for (const fileItem of iterator) {
      if (!skipFile(fileItem)) {
        // Cannot use return await here, it stops after 5 files
        const result = await this.fileUploadService.asyncUpload(fileItem);
        this.results.push(result);
      }
    }
  }
}
