/**
 * This is the main File Manager component.
 */

import React, { useMemo, useContext, useState, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { ContextMenuContext } from '../../providers/ContextMenuContext';
import { useSelector } from 'react-redux';
import { UploadContext } from '../UploadContext';

import DataViewerToolbar from './DataViewerToolbar';
import { useFolderInfo } from '../../api/getFolderInfo';
import { useFolderPath } from '../../api/getFolderPath';
import { useOrgRootList } from '../../../organization/api/getOrgRootList';
import {
  useSetSelectData,
  useSetSelectDataCurrent,
} from '../../utils/useSelectData';
import { useIsCheck } from '../../utils/useParams';
import {
  DataLoadingComponent,
  DataViewerErrorFallback,
} from './ApiErrorBoundary';
import { useCurrentDataList } from '../../api/getCurrentDataList';
import AgGridTable from './AgGridTable';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';

import { searchOrgStorageCommand } from '../../../../api/rest';
import { useRouteMatch, useParams } from 'react-router-dom';
import { isConferencePage } from '../Conference/StorageConference';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    height: '100vh',
  },
  progress: {
    margin: theme.spacing(2, 1),
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'transparent',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  MaxHeight: `100vh`,
};
const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const flex_style = {
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1px',
};

function DataViewer() {
  const classes = useStyles();
  const setSelectData = useSetSelectData();
  const setSelectDataCurrent = useSetSelectDataCurrent();
  const { makeOnDrop, makeOnDropRoot } = useContext(UploadContext);
  const { openContextMenu } = useContext(ContextMenuContext);
  const folderInfo = useFolderInfo({ useErrorBoundary: true });
  const folderPath = useFolderPath({ useErrorBoundary: true });
  const { data: orgDetails } = useOrgDetail();

  const { isRootPage } = useIsCheck();
  const rootPermName = useSelector(state => state.storageManager.rootPermName);
  // The list of items shown in the current File Manager folder
  const dataList = useCurrentDataList();

  // Customize the display for conference view
  const isConference = isConferencePage();
  const isFileManagerPath =
    window.location.pathname.startsWith('/storage/org');

  // Customize the display for Filter view
  const [filterData, setFilterData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const {
    patientId,
    filter,
  } = useParams();
  const isFilterview = filter === 'filter';

  // TODO: fix this error when loading a URL with filter view:
  http://127.0.0.1:8000/api/search/org-storage-command/undefined?query=patientid:%229081792874%22&search_field=all&limit=9999 404 (Not Found)

  // This useEffect is triggered once the org has been set
  useEffect(() => {
    const getFilterData = async () => {
      const result = await searchOrgStorageCommand(
        orgDetails.uuid,
        'patientid:"9081792874"',
        'all',
        9999
      );
      console.debug('Filter result', result['folder']);
      // set Filter data here
      setFilterData(result['folder']);
    }

    if (isFilterview) {
      // Ignore if org is not yet set
      if (!orgDetails) {
        console.debug('Org not set, ignore');
        return;
      }
      // If the URL matches /filter, extract query parameters
      console.debug('FILTER VIEW, patientId', patientId);
      // Fetch data based on these filters, same structure as dataList
      getFilterData();
    }

  }, [orgDetails, isFilter]);

  const current_folder = useMemo(() => {
    if (folderInfo.data) {
      console.log('Current folder', folderInfo.data.current_folder);
      return folderInfo.data.current_folder;
    } else {
      console.log('Current folder undefined');
      return undefined;
    }
  }, [folderInfo.data]);

  const openContextMenuAtPosition = (x, y) => {
    if (isRootPage()) {
      // root
      const option = {
        position: {
          left: x,
          top: y,
        },
        isRoot: true,
        rootPermName: rootPermName,
      };
      openContextMenu({}, option);
    } else {
      // folder
      setSelectData({
        type: 'folder',
        data: current_folder,
      });
      const option = {
        position: {
          left: x,
          top: y,
        },
      };
      openContextMenu(current_folder, option);
    }
  };
  // contextmenu
  const handleContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    openContextMenuAtPosition(event.clientX - 2, event.clientY - 4);
  };

  // upload
  const onDrop = isRootPage()
    ? makeOnDropRoot(orgDetails.uuid, rootPermName)
    : makeOnDrop(current_folder);

  // drop-zone
  const {
    getRootProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled:
      !isRootPage() &&
      (current_folder == undefined ||
        current_folder.foldertype == undefined ||
        current_folder.foldertype == 'series' ||
        current_folder.foldertype == 'study'),
    //noDragEventsBubbling: true,
    onDrop: onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (dataList === undefined && !filterData) {
    // Display the MUI CircularProgress loading icon, instead of data table
    console.log('NO DATA YET');
    return <DataLoadingComponent />;
  }
  else if (dataList) {
    // console.log('dataList', dataList);
  }

  /** This is the Main file manager list of files */
  return (
    <>
      <div
        className={classes.root}
        onClick={setSelectDataCurrent}
        onContextMenu={handleContextMenu}
        style={flex_style}
      >

        {isFileManagerPath && (
          <div>
            {/*
            isFileManagerPath
              Show this toolbar (フォルダのパスを表示する) for S9 File Manager pages.
              Even if using the conference.* domain.
              This will not be shown in Conference edition pages, since it never
              uses /storage/org in the URL.
            */}
            <Toolbar />
            <DataViewerToolbar
              folderPath={folderPath.data}
              folderInfo={folderInfo.data}
              openContextMenuAtPosition={openContextMenuAtPosition}
            />
          </div>
        )}

        <div {...getRootProps({ style })}>
          {/* If filterData is not empty, use a different dataList
            for filter view (not viewing a specific folder) */}
          {isFilterview ? (
            <AgGridTable
              dataList={filterData}
              folderPath={folderPath.data}
              orgId={orgDetails.uuid}
            />
          ) : (
            <div>
              {/* Main file manager */}
              <AgGridTable
                dataList={dataList}
                folderPath={folderPath.data}
                orgId={orgDetails.uuid}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

DataViewer.propTypes = {};
export default DataViewer;
