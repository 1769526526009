import { UploadCancelError } from '.';
import { checkFullAccess } from '../checkAccessPerm';

export const checkItemsFullAccessAndAlert = items => {
  items.forEach(item => {
    if (!checkFullAccess(item.your_perm)) {
      alert(
        `同名アイテム「${item.name}」が存在しますがフルアクセス権限がありません。名前を変えてアップロードするか別の場所にアップロードしてください。`
      );
      throw new UploadCancelError('no full access');
    }
  });
};
