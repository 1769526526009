import throttle from 'lodash.throttle';

export class UploadProgressManager {
  constructor(
    files, // アップロード対象のファイルarray
    setProgress // プログレスバーのセット(100%表示)
  ) {
    this.files = files;
    this._setProgress = setProgress;
    this.sumSize = this.files.reduce(function(sum, element) {
      return sum + element.size;
    }, 0);

    // アップロード完了時の量
    this.uploadedSizeObj = {};
    this.files.forEach(file => {
      this.uploadedSizeObj[file.path] = { uploadedSize: 0, size: file.size };
    });

    this.setProgress = throttle(this.setProgress, 100);
  }

  setProgress() {
    const nowTotalUploadedSize = Object.values(this.uploadedSizeObj).reduce(
      function(sum, element) {
        return sum + element.uploadedSize;
      },
      0
    );
    const percentCompleted = Math.round(
      (nowTotalUploadedSize * 100) / this.sumSize
    );
    this._setProgress(percentCompleted);
  }

  onUploadProgress(path, rate) {
    this.uploadedSizeObj[path]['uploadedSize'] =
      rate * this.uploadedSizeObj[path]['size'];
    this.setProgress();
  }
}
