import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

// Ag Grid imports
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './AgGridTable.css';

import {
  AgGridTableToolbar,
  getInitSelectedCOlumnFields,
} from './AgGridTableToolbar';
import AgGridCellRenderer from './AgGridCellRenderer';
import {
  allColumnsDefs,
  ActiveRowProvider,
  makeDropZoneIDforFolder,
  makeDropZoneIDforRoot,
  checkCanMove,
  useMoveData,
} from '../../utils/aggridUtils';
import { useSelectData } from '../../utils/useSelectData';
import { SELECTED_COLUMN_FIELDS } from '@ohif/viewer/src/utils/localStorageKeys';
import { isMobileOrTablet } from '../../../../utils/detectDevice';

// Conference page customization
import { isConferencePage } from '../Conference/StorageConference';
import { userLoggedIn } from '../../../../api/auth';

function AgGridTable(props) {
  /*
  参考
    - [x] 基本データ
      - [x] rowData: props.dataList
      - [x] columnsDefs
        - [x] 定義
          - allColumnsDefs from aggridUtils
        - [x] 保存・読み込み・ツールバーのカラムについて:
          - AgGridTableToolbar.js: 順番・非表示(hide)かどうかを表す selectedColumns をlocalStorageに保存
          - savedColumnsOrder < urlParamColumnsOrder として表示するためのカラムを決定
          - AgGridTableのカラムが変更された時(onColumnMoved) selectedColumnsをupdate
    - [x] moving Drag & Drop
      - [x] moving Drag & Drop (テーブル内)
      - [x] moving Drag & Drop (テーブル外)
      - 方法
          - Example Highlighted Tree Data: https://www.ag-grid.com/react-data-grid/row-dragging/#example-highlighted-tree-data
          - 方法1：dndSource=true が rowDragEntireRow と競合するため dndSource=trueを利用
              - nameカラムを左端に固定
              - デメリット：行全体のドラッグ＆ドロップができない＆実装が複雑
          - 方法２：custom-drag-component
              - https://www.ag-grid.com/react-data-grid/drag-and-drop/#custom-drag-component
              - nameカラムを左端に固定
              - デメリット：行全体のドラッグ＆ドロップができない＆実装が複雑
          - **方法3：行全体のドラッグ&ドロップ ** ←採用
              - Entire Row Dragging: https://www.ag-grid.com/react-data-grid/row-dragging/#entire-row-dragging
              - External DropZone: https://www.ag-grid.com/react-data-grid/row-dragging-to-external-dropzone/
              - テーブル内のドラッグ&ドロップ : rowDragEntireRow（onRowDragLeave,onRowDragMove,onRowDragEnd）
              - テーブル外のドラッグ&ドロップ :  addRowDropZone
              - デメリット：ドロップ先(dropzone)のelementしか使えないので、処理がドロップ先で実行できない
    - [x] upload Drag & Drop
      - [x] [new] upload Drag & Drop (row): 行に対してドラッグ&ドロップでアップロード可能
          - **方法1. Cellのpaddingをなくしてdropzoneの範囲を広くする** ←採用
            - 隙間がなくなるようにする: ag-cell の css
            - onDragActive 時のスタイリング
              - 方法1-1: getRowClass: rowの再レンダリングが必要。CellRenderer も再レンダリングされてうまく行かない
              - 方法1-2: セルごとにstyleを適用するため、行で共通する情報を CellRendererのprops.nodeに保持：stateでないのでuseEffectでtriggerにならない時がある
              - **方法1-3: セルごとにstyleを適用するため、行で共通する情報をuseContextに保持** ←採用
            - 今後の可能性：nameセルに対してアップロード可能→nameだけにできれば良い。ほかは全体に対してアップロードに変えるなど
          - 方法2. getRootPropsをrowのdivに与える方法を考える
            - aggridのメソッドやコンポーネントには無い。
      - [x] upload Drag & Drop (current folder)
        - 今までと同じでOK
    - [x] 行の context menu, click・doubleClick, がうまく動作するようにする（onRowContextMenuはaggridに無い）
      - **方法1. CellRendererで実現。Cellのpaddingをなくして行へのアクションができているように錯覚させる**←採用
        - CellRendererで実現
        - セル同士の隙間ができてしまわないようにする必要がある
      - 方法2. 行のdivに対して右クリックなどのアクションができるようにする
        -  onRowClickはあるが、onRowContextMenuはaggridに無い。また、'.ag-row' をカスタムする方法はなさそうだったので難しい
    - [x] 行の選択 aggridとselectDataの対応
      - クリックなどの動作（CellRendererで実現）→selectDataの変更→aggridの選択の変更
      - 行のクリック・右クリックなどをしたらreact上でsetSelectDataで反映
      - 基本的にはテーブルの外でクリックなど：deselectAll メソッド
        - https://www.ag-grid.com/react-data-grid/grid-api/
      - 今後の可能性：複数選択
        - https://www.ag-grid.com/react-data-grid/row-selection/
    - [ ] ページネーション
  */

  const gridRef = useRef();

  /*
  /
  / for columns
  /
  for AgGridTableToolbar.js & savedColumnsOrder
    - selectedColumns
      e.g.) [{field:'name', headerName:'名前', hide: false}]

  for columnDefs of AgGridReact
  priority: savedColumnsOrder < urlParamColumnsOrder  e.g.) ['name', 'type']
    - savedColumnsOrder:（selectedColumns のうちunhideのもの）
    - urlParamColumnsOrder: （urlで指定されたカラムの順番, params ）e.g.) ?columns=type,name
  */

  // 学会用のデフォルト columns
  let conferenceColumns = [
    {"field":"name","headerName":"名前","hide":false},
    {"field":"modality_list","headerName":"モダリティ","hide":false},
    {"field":"bodypartexamined_list","headerName":"検査部位","hide":false},
    {"field":"instance_count","headerName":"インスタンス数","hide":false},
    {"field":"series_count","headerName":"シリーズ数","hide":false},
    {"field":"type","headerName":"type","hide":false},
    {"field":"created_dt","headerName":"アップロード日時","hide":false},
    {"field":"size","headerName":"サイズ","hide":false},
    {"field":"PatientSex","headerName":"性別","hide":false},
    {"field":"PatientID","headerName":"患者ID","hide":false},
    {"field":"ProtocolName","headerName":"ProtocolName","hide":false},
    {"field":"ReferringPhysicianName","headerName":"参照医師","hide":false},
    {"field":"InstitutionName","headerName":"診療所","hide":false},
    {"field":"action","headerName":"アクション","hide":true},
    {"field":"uuid","headerName":"ID","hide":true},
    {"field":"tags","headerName":"タグ","hide":true},
  ];

  // Customize the display for conference view
  const isConference = isConferencePage();

  const [selectedColumns, setSelectedColumns] = useState([]);
  useEffect(() => {

    const initSelectedColumnFields = getInitSelectedCOlumnFields();
    let selectedColumnFields;
    if (isConference) {
      // In a Conference, use a fixed set of columns,
      // ignoring the customization in S9 file manager
      async function checkUser() {
        const isLoggedIn = await userLoggedIn();
        if (isConference && isLoggedIn) {
          conferenceColumns.splice(1, 0,
            {"field":"share","headerName":"共有","hide":false},
          );
        }
        console.debug('conferenceColumns', conferenceColumns);
        setSelectedColumns(conferenceColumns);
      }
      checkUser();
    } else{

      // Not in a Conference, allow user to customize columns
      // User's selected columns are in SELECTED_COLUMN_FIELDS in localStorage
      selectedColumnFields = JSON.parse(
        window.localStorage.getItem(SELECTED_COLUMN_FIELDS)
      );

      if (selectedColumnFields !== null) {
        const selectedColumnFieldsIncluded = selectedColumnFields.filter(
          selectedCol =>
            initSelectedColumnFields
              .map(col => col.field)
              .includes(selectedCol.field)
        );
        const initSelectedColumnFieldsExcluded = initSelectedColumnFields.filter(
          col =>
            !selectedColumnFields
              .map(selectedCol => selectedCol.field)
              .includes(col.field)
        );
        setSelectedColumns([
          ...selectedColumnFieldsIncluded,
          ...initSelectedColumnFieldsExcluded,
        ]);
      } else {
        setSelectedColumns(initSelectedColumnFields);
      }
    }
  }, []);

  const saveSelectedColumns = useCallback(newSelectedColumns => {
    setSelectedColumns(newSelectedColumns);
    window.localStorage.setItem(
      SELECTED_COLUMN_FIELDS,
      JSON.stringify(newSelectedColumns)
    );
  }, []);

  const selectedColumnOrder = useMemo(() => {
    return selectedColumns.filter(col => !col.hide).map(col => col.field);
  }, [selectedColumns]);

  const urlParamColumnsOrder = useMemo(() => {
    const url = new URL(window.location.href);
    const urlParamColumnsStr = url.searchParams.get('columns');
    if (urlParamColumnsStr !== null && urlParamColumnsStr !== '') {
      return urlParamColumnsStr.split(',');
    } else {
      return [];
    }
  }, []);

  const columnDefs = useMemo(() => {
    // priority:  savedColumnsOrder < urlParamColumnsOrder
    let usedColumnsOrder = selectedColumnOrder;
    if (urlParamColumnsOrder.length > 0) {
      usedColumnsOrder = urlParamColumnsOrder;
    }
    // 実際に存在するfieldのみになるようにfilter
    const safeUsedColumnsOrder = usedColumnsOrder.filter(field =>
      allColumnsDefs.map(colDef => colDef.field).includes(field)
    );
    // safeUsedColumnsOrderの順番でcolDefを作成
    return safeUsedColumnsOrder.map(field =>
      allColumnsDefs.find(colDef => colDef.field == field)
    );
  }, [selectedColumnOrder, urlParamColumnsOrder]);

  const setColumnDefs = useCallback(colDefs => {
    gridRef.current.api.setColumnDefs(colDefs);
  }, []);

  const onColumnMoved = useCallback(
    e => {
      const nowColDefs = gridRef.current.api.getColumnDefs();
      // 現在のaggridの順番でunhideなカラム
      const unhideColumns = nowColDefs.map(nowCol =>
        selectedColumns.find(selectedCol => selectedCol.field == nowCol.field)
      );
      // 残りのカラム
      const hideColumns = selectedColumns.filter(
        selectedCol =>
          !nowColDefs.map(col => col.field).includes(selectedCol.field)
      );
      // 順番に並べる
      const newSelectedColumns = [...unhideColumns, ...hideColumns];
      saveSelectedColumns(newSelectedColumns);
    },
    [saveSelectedColumns, selectedColumns]
  );

  /*
  //
  // moving Drag & Drop (テーブル内)
  //
  //  https://www.ag-grid.com/react-data-grid/row-dragging/#example-highlighted-tree-data
  */
  const moveData = useMoveData();
  const [potentialParent, setPotentialParent] = useState(null);
  const cellClassRules = {
    'ag-hover-over': params => {
      return params.node === potentialParent;
    },
  };
  const setPotentialParentForNode = useCallback(
    (api, node, overNode) => {
      var newPotentialParent;
      if (overNode && checkCanMove(node.data, overNode.data)) {
        newPotentialParent = overNode;
      } else {
        newPotentialParent = null;
      }
      let alreadySelected = potentialParent === newPotentialParent;
      if (alreadySelected) {
        return;
      }
      // we refresh the previous selection (if it exists) to clear
      // the highlighted and then the new selection.
      var rowsToRefresh = [];
      if (potentialParent) {
        rowsToRefresh.push(potentialParent);
      }
      if (newPotentialParent) {
        rowsToRefresh.push(newPotentialParent);
      }
      setPotentialParent(newPotentialParent);
      refreshRows(api, rowsToRefresh);
    },
    [potentialParent]
  );

  const refreshRows = (api, rowsToRefresh) => {
    var params = {
      // refresh these rows only.
      rowNodes: rowsToRefresh,
      // because the grid does change detection, the refresh
      // will not happen because the underlying value has not
      // changed. to get around this, we force the refresh,
      // which skips change detection.
      force: true,
    };
    api.refreshCells(params);
  };

  const onRowDragMove = useCallback(
    event => {
      setPotentialParentForNode(event.api, event.node, event.overNode);
      console.log('Row drag');
    },
    [setPotentialParentForNode]
  );
  const onRowDragLeave = useCallback(
    event => {
      setPotentialParentForNode(event.api, event.node, null);
      console.log('Row drag leave');
    },
    [setPotentialParentForNode]
  );
  const onRowDragEnd = useCallback(
    event => {
      if (!potentialParent) {
        return;
      }
      moveData(event.node.data, potentialParent.data);
    },
    [moveData, potentialParent]
  );

  /*
  /
  / moving Drag & Drop (テーブル外)
  /
  */
  const addRowDropZoneFromFolderPath = useCallback(
    params => {
      const addDropZonesOutSideTableForFolderOrRoot = (
        params,
        folderOrRoot
      ) => {
        // target container element
        let container = null;
        if (
          folderOrRoot.type === 'private' ||
          folderOrRoot.type === 'share' ||
          folderOrRoot.type === 'org'
        ) {
          // root
          container = document.getElementById(
            makeDropZoneIDforRoot(folderOrRoot)
          );
          if (container == null) return;
        } else {
          // folder
          container = document.getElementById(
            makeDropZoneIDforFolder(folderOrRoot)
          );
          if (container == null) return;
        }

        // define onDrag...
        const dropZone = {
          getContainer: () => {
            return container;
          },
          onDragging: params => {
            if (checkCanMove(params.node.data, folderOrRoot)) {
              container.classList.add('ag-hover-over');
            }
          },
          onDragLeave: params => {
            container.classList.remove('ag-hover-over');
          },
          onDragStop: params => {
            container.classList.remove('ag-hover-over');
            if (checkCanMove(params.node.data, folderOrRoot)) {
              moveData(params.node.data, folderOrRoot);
            }
          },
        };
        params.api.addRowDropZone(dropZone);
      };

      if (props.folderPath.length > 1) {
        props.folderPath.forEach((folderOrROot, idx, arr) => {
          if (idx !== arr.length - 1) {
            addDropZonesOutSideTableForFolderOrRoot(params, folderOrROot);
          }
        });
      }
    },
    [moveData, props.folderPath]
  );

  /*
  /
  / for select
  /
  */
  const selectData = useSelectData();
  useEffect(() => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      const renderedNodes = gridRef.current.api.getRenderedNodes();
      for (let ni = 0; ni < renderedNodes.length; ni++) {
        const node = renderedNodes[ni];
        if (node.data.uuid == selectData.data.uuid) {
          node.setSelected(true);
          break;
        }
        if (ni === renderedNodes.length - 1) {
          // no selected rows
          gridRef.current.api.deselectAll();
        }
      }
    }
  }, [selectData]);

  /*
  /
  / AgGrid
  /
  */
  const defaultColDef = useMemo(() => {
    return {
      cellRenderer: AgGridCellRenderer,
      resizable: true,
      sortable: true,
      cellClassRules: cellClassRules,
    };
  }, [cellClassRules]);

  // Dragging is enabled for the entire row if using a Desktop browser
  // For tablet or mobile, only allow dragging from the drag handle
  const dragEntireRow = isMobileOrTablet() ? false : true;
  console.debug('AgGridTable props', props);

  return (
    <>
      <div className="ag-theme-alpine">
        {!isConference && (
          <div>
              <AgGridTableToolbar
                selectedColumns={selectedColumns}
                saveSelectedColumns={saveSelectedColumns}
                setColumnDefs={setColumnDefs}
                columnDefs={columnDefs}
                isSetParamsColumns={urlParamColumnsOrder.length > 0}
                orgId={props.orgId}
              />
          </div>
        )}
        <ActiveRowProvider>
          <AgGridReact
            // style={{ width: '100%', height: '100%;' }}
            ref={gridRef}
            rowData={props.dataList}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            // moving columns
            suppressDragLeaveHidesColumns={true}
            onColumnMoved={onColumnMoved}

            // moving Drag & Drop
            // Use AG Grid's unmanaged drag & drop
            rowDragManaged={false}
            // Prevent entire row from being used as a drag target on mobile.
            // Only allow dragging on the drag handle at left end of each row.
            rowDragEntireRow={dragEntireRow}

            //// moving Drag & Drop(テーブル内)
            onRowDragLeave={onRowDragLeave}
            onRowDragMove={onRowDragMove}
            onRowDragEnd={onRowDragEnd}
            //// moving Drag & Drop(テーブル外)
            onRowDataUpdated={addRowDropZoneFromFolderPath} // onGridReady is not sufficient to add rerendered FolderPath.
            // row selection
            rowSelection={'single'}
            suppressRowClickSelection={true}
            onRowDoubleClicked={p => {}}
            onSelectionChanged={p => {}}
            // layout, others
            suppressCellFocus={true}
            suppressRowHoverHighlight={true}
            domLayout="autoHeight"
            //paginationAutoPageSize={true}
            //pagination={true}
            resizable={true}
          />
        </ActiveRowProvider>
      </div>
    </>
  );
}

AgGridTable.propTypes = {
  dataList: PropTypes.array.isRequired,
  folderPath: PropTypes.array.isRequired,
};
export default AgGridTable;
