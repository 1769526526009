import React, { createContext, useMemo } from 'react';
import propsTypes from 'prop-types';

import SnackbarBox from '../components/snackbar/SnackbarBox';
import { LinearProgressWithLabel } from '../components/Progress';

const SnackbarContext = createContext({});

const SnackbarProvider = props => {
  // snackbar
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarCollapse, setOpenSnackbarCollapse] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [progressValue, setProgressValue] = React.useState(-1);
  const handleCloseSnackbar = event => {
    setOpenSnackbar(false);
  };
  const completeProgress = () => setProgressValue(100);

  const handleSnackbarCompleted = (message = '', timeout = 5000) => {
    console.debug('handleSnackbarCompleted', message);
    completeProgress();
    setSnackbarMessage(message);
    setOpenSnackbarCollapse(false);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, timeout);
  }

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        setOpenSnackbar,
        openSnackbarCollapse,
        setOpenSnackbarCollapse,
        snackbarMessage,
        setSnackbarMessage,
        progressValue,
        setProgressValue,
        handleSnackbarCompleted,
      }}
    >
      <SnackbarBox
        openSnackbar={openSnackbar}
        openCollapse={openSnackbarCollapse}
        setOpenCollapse={setOpenSnackbarCollapse}
        handleCloseSnackbar={handleCloseSnackbar}
        title={snackbarMessage}
      >
        {progressValue >= 0 && (
          <LinearProgressWithLabel
            variant="determinate"
            value={progressValue}
          />
        )}
      </SnackbarBox>
      {props.children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propsTypes = {
  children: propsTypes.node.isRequired,
};

export { SnackbarContext, SnackbarProvider };
