import axios from 'axios';
import { anonymizeAndGetMetadata } from '../../lib/filesToStudies';
import { DICOM_FILETYPE } from '../constants';
import { uploadOneDicom, uploadOneFile } from '../../api/rest';

export class FileUploadService {
  constructor(
    targetFolderUUID,
    targetOrgUUID,
    isOrgPerm,
    uploadProgressManager
  ) {
    this.targetFolderUUID = targetFolderUUID;
    this.targetOrgUUID = targetOrgUUID;
    this.isOrgPerm = isOrgPerm;
    this.uploadProgressManager = uploadProgressManager;
  }

  async asyncUpload(fileItem) {
    /*
    アップロード処理
    */
    if (fileItem.filetype == DICOM_FILETYPE) {
      return await this.uploadDicom(fileItem);
    } else {
      await this.uploadNotDicom(fileItem);
    }
  }

  async uploadDicom(fileItem) {
    // 必要があれば匿名化・編集化の処理を行う
    const newFile = await anonymizeAndGetMetadata(fileItem);
    console.debug('anonymizeAndGetMetadata', newFile);
    const fileData = this.extractFileData(newFile); // backendに送るためにblob以外を取り出す
    console.log('uploadDicom', fileData);
    const response = await uploadOneDicom({
      file_data: fileData,
      target_folder: this.targetFolderUUID,
      target_org: this.targetOrgUUID,
      is_org_perm: this.isOrgPerm,
    });
    console.debug('Creating DICOM record complete', response.data.study);
    await this.uploadStorage(response.data.session_uri, newFile);
    return response.data.study;

    /*
    // session_uriの取得→GCSにアップロード
    */
  }
  async uploadNotDicom(fileItem) {
    const fileData = this.extractFileData(fileItem); // backendに送るためにblob以外を取り出す
    const response = await uploadOneFile({
      file_data: fileData,
      target_folder: this.targetFolderUUID,
      target_org: this.targetOrgUUID,
      is_org_perm: this.isOrgPerm,
    });
    console.log('uploadNotDicom', fileData);
    await this.uploadStorage(response.data.session_uri, fileItem);
  }

  async uploadStorage(sessionUri, fileItem) {
    // TODO: 一時的にネットワークの障害が発生するなどアップロードに失敗したときに途中から再開するように変更する
    const option = {
      timeout: 60 * 60 * 1000, // 1h
      onUploadProgress: function(progressEvent) {
        const rate = progressEvent.loaded / progressEvent.total;
        this.uploadProgressManager.onUploadProgress(fileItem.path, rate);
      }.bind(this),
    };
    return axios.put(sessionUri, fileItem, option);
  }

  extractFileData(fileItem) {
    const fileData = {
      filetype: fileItem.filetype,
      metadata: fileItem.metadata,
      path: fileItem.path.normalize('NFC'),
      lastModified: fileItem.lastModified,
      name: fileItem.name.normalize('NFC'),
      size: fileItem.size,
      type: fileItem.type,
    };
    return fileData;
  }
}
