import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import propsTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ModalContext } from '../../../context/ModalContext';
import { SnackbarContext } from '../../../context/SnackbarContext';
import {
  addMimeTypeToFiles,
  // extractStudyMetadataFromPaths,
  UploadCancelError,
  extractDuplication,
  UploadService,
} from '../../../utils/upload';

import AnonymizeSimple from '../../annonymization/AnonymizeSimple';

import { splitDicomFiles } from '../../../utils/upload/extractStudyMetadataFromPaths';
import { filesToStudiesForUpload } from '../../../lib/filesToStudies';

import { checkFullAccess } from '../../../utils/checkAccessPerm';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';
import {
  useUpdateWhenDelete,
  useUpdateWhenUpload,
} from '../api/fetchUpdateInfo';
import { useFolderInfoReset } from '../api/getFolderInfo';
import { useOrgRootListInvalidate } from '../../organization/api/getOrgRootList';

import { checkItemsFullAccessAndAlert } from '../../../utils/upload/checkItemsFullAccessAndAlert';
import { deleteFolder, getTargetDataList, uploadPyDicomFile, setStudiesCompleted } from '../../../api/rest';
import { isConferencePage } from './Conference/StorageConference';
import { useOrgDetail } from '../../organization/api/getOrgDetail';
import UploadConfirmationModal from './Conference/UploadConfirmationModal';
import { generateUniviewerUrl } from '../../univiewer/UniversalViewerContainer';

const UploadContext = React.createContext({});

const UploadProvider = props => {
  // Import Modal contexts
  const {
    openAnonymizeDialog, openConfirmDuplicateModal,
  } = useContext(ModalContext);

  const {
    setOpenSnackbar,
    setSnackbarMessage,
    setProgressValue,
    setOpenSnackbarCollapse,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const updateWhenUpload = useUpdateWhenUpload();
  const updateWhenDelete = useUpdateWhenDelete();
  const {
    data: { memberPerm },
  } = useCheckOrgPerm();

  const [progress, setProgress] = React.useState(0);
  const resetProgress = () => {
    setProgress(progress => 0);
  };
  const completeProgress = () => setProgressValue(100);

  const refetchFolderInfo = useFolderInfoReset();
  const refetchOrgRootList = useOrgRootListInvalidate();

  const isConference = isConferencePage();
  let uploadSummaryDefault = {
    success: 0,
    errors: 0,
    messages: [],
    studies: {},
  }
  const [uploadedStudies, setUploadedStudies] = React.useState(uploadSummaryDefault);
  // const [uploadedStudies, setUploadedStudies] = React.useState([]);

  const [uploadOptions, setUploadOptions] = React.useState({});

  const [openSummary, setOpenSummary] = React.useState(false);
  const [openAnon, setOpenAnon] = React.useState(false);

  // useParams doesn't work inside a Context provider
  // https://stackoverflow.com/questions/70568571/why-doesnt-react-router-dom-useparams-hook-work-within-context
  // const { initialOrgId, parentUUID, folderUUID } = useParams();

  // Use regex instead to get the current org
  // Define a regular expression to extract org from the URL
  const orgRegex = /\/org\/([^\/]+)/;
  // Match the regex against the URL
  const match = window.location.href.match(orgRegex);

  // Extract the organization from the matched result
  const currentOrg = match ? match[1] : null;
  // if (currentOrg) {
  //   console.log('UploadProvider org', currentOrg);
  // }

  useEffect(() => {
    setProgressValue(progress);
  }, [progress, setProgressValue]);

  const handleOpenAnon = () => {
    console.log('Open simple Anon');
    setOpenAnon(true);
  };

  const handleAnonClose = () => {
    setOpenAnon(false);
  }

  const handleAnonOptions = (anonOptions) => {
    setOpenAnon(false);
    // Run with these options
    console.log('anonOptions', anonOptions, 'startUploads');
    // Wait for user input (anonOptions) returned from AnonymizeSimple, then:
    startUploads(anonOptions);
  }

  const handleOpenSummary = () => {
    setOpenSummary(true);
  };

  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const BATCH_SIZE = 10;  // Upload 10 files per batch
  let i = 0;

  async function uploadConcurrent(dcmFiles, options) {

    const totalFiles = dcmFiles.length;
    // Track status of uploaded data
    let uploadSummary = {
      success: 0,
      errors: 0,
      messages: [],
      studies: {},
    };

    console.log('uploadConcurrent', options);

    // Upload a batch of files and update progress bar
    const uploadFileWithProgress = async (dcmBatch) => {
      try {
        i += dcmBatch.length;
        // console.log(`Uploading batch ${dcmBatch.length}`);
        const resp = await uploadPyDicomFile({
          dcmBatch: dcmBatch,
          options: options,
        });

        const progress = Math.round(( i/totalFiles ) * 100);
        // Can use setProgress directly. (calls setProgressValue in the
        // Snackbar progress bar component)
        console.debug('Uploaded', i, 'progress', progress, '%');
        setSnackbarMessage(`アップロード中 (${i}/${totalFiles})`);
        setProgress(progress);
        return resp;
      } catch (error) {
        console.error(`Error uploading ${dcmBatch.name}:`, error);
        throw error;
      }
    };

    // Keep track of the active uploads (promises)
    const activeUploads = new Set();

    // Function to add a new upload to the active set
    const addNewUpload = async (dcmBatch) => {
      const uploadPromise = uploadFileWithProgress(dcmBatch)
        .then(() => activeUploads.delete(uploadPromise))  // Remove finished upload
        .catch(() => activeUploads.delete(uploadPromise));  // Also remove on failure
      activeUploads.add(uploadPromise);  // Add the new upload to active uploads
    };

    // Split the files into batches and iterate with concurrency control
    for (let batchStart = 0; batchStart < totalFiles; batchStart += BATCH_SIZE) {
      // Create a batch of files
      const dcmBatch = dcmFiles.slice(batchStart, batchStart + BATCH_SIZE);
      // Await each batch in sequence
      const batchLabel = 'Batch ' + batchStart;
      console.time(batchLabel);
      const batchResp = await uploadFileWithProgress(dcmBatch);
      console.timeEnd(batchLabel); // End the timer and log the time
      console.log('Batch status', batchResp);
      const respData = batchResp.data;
      if (respData) {
        // Get results and handle it
        const results = respData.results;
        // console.log('respData results', results);
        if (results) {
          // Iterate and count success, errors
          for (let result of results) {
            console.debug('result', result);
            if (result.success && result.success === true) {
              // Increment success counter
              uploadSummary.success += 1;
              // Handle each result
              const study = result.study;
              const series = result.series;
              if (!uploadSummary.studies.hasOwnProperty(study.StudyInstanceUID)) {
                // Initialize this study in summary dict
                console.log('Initialize this study in summary dict');
                uploadSummary.studies[study.StudyInstanceUID] = {
                  'modalities': new Set(),
                  'numSeries': new Set(),
                  'numInstances': 0,
                  'name': study.name,
                  // 'uuid': study.uuid,
                  'url': generateUniviewerUrl({
                    'name': study.name,
                    'uuid': study.uuid,
                    'org': currentOrg,
                  }, true),
                }
              }

              // Aggregrate study, series info for summary
              const studySummary  = uploadSummary.studies[study.StudyInstanceUID];
              console.debug('studySummary', studySummary);
              if (series.modality) {
                studySummary.modalities.add(series.modality);
              }
              if (series.SeriesInstanceUID) {
                studySummary.numSeries.add(series.SeriesInstanceUID);
              }
              studySummary.numInstances++;

            } else {
              uploadSummary.errors += 1;
              // Append error message, if available
              uploadSummary.messages.push(result);
            }
            // Print summary after adding this result
            console.log('uploadSummary', uploadSummary);
          }
        }
      }
    }

    console.log('Dataset upload finished!');
    handleSnackbarCompleted('アップロードが完了しました');

    // Mark all studies as upload completed in backend
    const resp = await setStudiesCompleted(
      Object.keys(uploadSummary.studies),  // studyList
      options.target_folder,  // folder where it was uploaded
    );
    if (resp.status===201) {
      const results = resp.data.results;
      console.log('setStudiesCompleted Data:', results);
    } else {
      console.log('setStudiesCompleted Resp:', resp);
    }

    // Refresh folder contents
    console.log('Refresh folder contents, ENABLED');
    let targetFolderUUID = options.target_folder;
    if (!targetFolderUUID) {
      refetchOrgRootList();
    } else {
      refetchFolderInfo({ folderUUID: targetFolderUUID });
      // refetchFolderInfo({
      //   folderUUID: targetFolderUUID,
      //   apiOptions: { noFile: true, noUrl: true, combine: false },
      // });
    }

    // Display summary after uploading is finished
    handleOpenSummary();
    setUploadedStudies(uploadSummary);

  }  // End uploadConcurrent

  async function startUploads(anonOptions) {

    // Get from state
    const { dcmFiles, targetFolderUUID, targetOrgUUID, isOrgPerm} = uploadOptions;
    const totalFiles = dcmFiles.length;
    console.log('Upload pydicom:', totalFiles, 'files, to:', targetFolderUUID, 'org:', targetOrgUUID);
    setSnackbarMessage('アップロード中');
    const options = {
      anon_options: anonOptions, target_folder: targetFolderUUID,
      target_org: targetOrgUUID, is_org_perm: isOrgPerm
    };
    await uploadConcurrent(dcmFiles, options);
    handleSnackbarCompleted('アップロードが完了しました');

    // Refresh folder contents
    console.log('Refresh folder contents, ENABLED');
    if (!targetFolderUUID) {
      refetchOrgRootList();
    } else {
      refetchFolderInfo({ folderUUID: targetFolderUUID });
      refetchFolderInfo({
        folderUUID: targetFolderUUID,
        apiOptions: { noFile: true, noUrl: true, combine: false },
      });
    }
  }

  const uploadFiles = async (
    files,
    targetFolderUUID,
    targetOrgUUID = '',
    isOrgPerm = false
  ) => {
    try {
      resetProgress();
      setOpenSnackbar(true);
      setSnackbarMessage('アップロード準備中');

      const useDcmjs = false;

      // mime type を追加
      const filesWithMime = await addMimeTypeToFiles(files, setSnackbarMessage);

      // dicomとdicom以外のファイルに分ける
      const [
        dcmFiles,
        notDcmFiles,
      ] = await splitDicomFiles(filesWithMime);

      setUploadOptions({
        dcmFiles: dcmFiles,
        notDcmFiles: notDcmFiles,
        targetFolderUUID: targetFolderUUID,
        targetOrgUUID: targetOrgUUID,
        isOrgPerm: isOrgPerm
      });

      // Popup a modal dialog, before starting upload, with anonymizations options.
      // Options chosen by the user are passed to startUploads.
      handleOpenAnon();

      // dcmjs is deprecated, return here
      if (!useDcmjs) {
        console.warn('No more Dcmjs!');
        return;
      }

      // Below is using dcmjs
      // studyの情報を抽出
      const studies = filesToStudiesForUpload(dcmFiles);

      // dicomが存在すれば匿名化・編集を行う画面を表示
      // modalをopenしてpromiseがresolveされるまで待機
      let newStudyData = [];
      let isAnonymize = false;
      if (studies.length > 0) {
        [newStudyData, isAnonymize] = await new Promise(function(
          resolve,
          reject
        ) {
          openAnonymizeDialog(studies, { resolve, reject });
        });
      }

      // upload先のデータ一覧取得
      const targetDataList = await getTargetDataList({
        targetFolderUUID,
        targetOrgUUID,
      });
      console.log('getTargetDataList UPLOAD UPLOAD ********', targetDataList);

      // 重複しているデータをtargetDataListから抽出
      const [
        duplicatedStudies,
        duplicatedFolders,
        duplicatedFiles,
      ] = extractDuplication(
        targetDataList,
        newStudyData,
        isAnonymize,
        notDcmFiles
      );

      // 重複対象がフルアクセス権限を持つかどうかを確認
      checkItemsFullAccessAndAlert([
        ...duplicatedFiles,
        ...duplicatedStudies,
        ...duplicatedFolders,
      ]);

      // 重複を確認し存在すれば置き換えるかキャンセルするか確認
      let duplicatedOptions = { studyReplace: false, folderReplace: false };
      if (
        duplicatedStudies.length > 0 ||
        duplicatedFolders.length > 0 ||
        duplicatedFiles.length > 0
      ) {
        duplicatedOptions = await new Promise(function(resolve, reject) {
          openConfirmDuplicateModal({ resolve, reject });
        });
        // "studyの置き換え(studyReplace)"を指定したらstudyを削除
        if (duplicatedOptions.studyReplace) {
          await Promise.all(
            duplicatedStudies.map(item => {
              deleteFolder(item.uuid);
            })
          );
          updateWhenDelete(duplicatedStudies);
        }
        // folderの重複は削除してからアップロード
        if (duplicatedOptions.folderReplace) {
          await Promise.all(
            duplicatedFolders.map(item => deleteFolder(item.uuid))
          );
          updateWhenDelete(duplicatedFolders);
        }
        // fileの重複は削除せずにそのままアップロード
      }

      // アップロード
      console.log('READY TO UPLOAD');
      setSnackbarMessage('アップロード中');
      setOpenSnackbarCollapse(true);
      const uploadService = new UploadService({
        files: filesWithMime,
        targetFolderUUID: targetFolderUUID,
        targetOrgUUID: targetOrgUUID,
        isOrgPerm: isOrgPerm,
        setProgress: setProgress,
      });
      const results = await uploadService.asyncAllUpload();

      // 完了通知
      handleSnackbarCompleted('アップロードが完了しました');
      console.log('UPLOAD COMPLETE');

      // Show summary for an upload from Conference page
      if ((isConference) && (studies) && (studies.length > 0)) {
        // TODO: aggregate server results and studies here
        console.log('UploadProvider studies', studies, 'results', results);
        // Open the summary window only if studies is not empty
        let studySummary = {};
        let modalities = new Set();
        // Assume that the first study matches the result from backend
        const study0 = studies[0];
        let study0uuid = '';
        const numSeries = study0.series.length;
        let numInstances = 0;
        for (const s of study0.series) {
          modalities.add(s.Modality);
          numInstances += s.instances.length;
        }
        for (var result of results) {
          if ((result !== undefined) && (result.name) && (result.uuid)) {

            const urlData = {
                name: result.name,
                uuid: result.uuid,
                org: currentOrg,
            }
            // const univiewerUrl = generateUniviewerUrl(urlData);
            result.url = generateUniviewerUrl(urlData, true);
            // Add to study dict
            studySummary[result.uuid] = result;
            study0uuid = result.uuid;
          }
        }

        // Set additional data for studySummary
        studySummary[study0uuid].modalities = modalities;
        studySummary[study0uuid].numSeries = numSeries;
        studySummary[study0uuid].numInstances = numInstances;

        console.log('studySummary', studySummary, 'study0uuid', study0uuid);

        // Display study results after uploading
        handleOpenSummary();
        setUploadedStudies(studySummary);
      }

    } catch (err) {
      console.log('Upload Error', err);
      let message = '';
      if (err instanceof UploadCancelError) {
        // キャンセル時
        message = 'アップロードをキャンセルしました';
        toast.info(message);
      } else if (err instanceof DOMException) {
        message = 'アップロードが失敗しました。';
        toast.error(
          'アップロードが失敗しました。ファイルサイズが大きすぎる可能性があります。'
        );
      } else if (err.response && err.response.status == 507) {
        message = 'アップロードが失敗しました。';
        toast.error('アップロードが失敗しました。容量制限を超えています');
      }
      // Handle snackbar completion
      handleSnackbarCompleted(message);
    }
  };

  const makeOnDrop = (uploadFolder, isFetch = true) => {
    const onDrop = async files => {
      if (checkFullAccess(uploadFolder.perm)) {
        console.log('makeOnDrop uploadFiles', files.length);
        await uploadFiles(files, uploadFolder.uuid, '', false);
        if (isFetch && window.location.pathname.includes(uploadFolder.uuid)) {
          await updateWhenUpload(uploadFolder);
        }
      } else {
        alert('アップロードにはフルアクセス権限が必要です');
      }
    };
    return onDrop;
  };

  const makeOnDropRoot = (targetOrgUUID = '', rootPermName, isFetch = true) => {
    const onDrop = async files => {
      if (memberPerm) {
        if (rootPermName === 'share') {
          alert('データはプライベート内にアップロードされます。');
        }
        const isOrgPerm = rootPermName === 'org' ? true : false;
        console.log('makeOnDropRoot uploadFiles', files);
        await uploadFiles(files, '', targetOrgUUID, isOrgPerm);
        if (isFetch) {
          await updateWhenUpload();
        }
      } else {
        alert('アップロードには組織に参加する必要があります。');
      }
    };
    return onDrop;
  };

  return (
    <div>
      <UploadConfirmationModal
        open={openSummary}
        handleClose={handleCloseSummary}
        // uploadedStudies={uploadedStudies}
        uploadSummary={uploadedStudies}
      />

      <AnonymizeSimple
        open={openAnon}
        handleAnonOptions={handleAnonOptions}
        handleAnonClose={handleAnonClose}
      />



      <UploadContext.Provider
        value={{
          progress,
          setProgress,
          resetProgress,
          completeProgress,
          makeOnDrop,
          makeOnDropRoot,
          uploadFiles,
        }}
      >
        {props.children}
      </UploadContext.Provider>
    </div>
  );
};

UploadProvider.propsTypes = {
  props: propsTypes.node.isRequired,
};

export { UploadContext, UploadProvider };
