export const extractDuplication = (
  currentDataList,
  newStudyData,
  isAnonymize,
  notDcmFiles,
  normalizeForm = 'NFC'
) => {
  /*
    currentDataListの中から重複しているものを抽出する
  */

  let duplicatedStudies = [];
  let duplicatedFolders = [];
  let duplicatedFiles = [];

  // study(studyInstanceUID)の重複確認。匿名化時はHashedStudyInstanceUIDを参照
  const uploadStudyInstanceUIDs = newStudyData.map(item => {
    if (isAnonymize) {
      return item.HashedStudyInstanceUID;
    } else {
      return item.StudyInstanceUID;
    }
  });
  duplicatedStudies = currentDataList.filter(item => {
    return (
      item.object_type == 'folder' &&
      item.foldertype == 'study' &&
      uploadStudyInstanceUIDs.includes(item.StudyInstanceUID)
    );
  });

  //  folderとfileのname重複確認
  const currentFolders = currentDataList.filter(item => {
    return (
      item.object_type == 'folder' &&
      item.foldertype != 'study' &&
      item.foldertype != 'series'
    );
  });
  const currentFolderNames = currentFolders.map(item => item.name);
  const currentFiles = currentDataList.filter(item => {
    return item.object_type == 'file';
  });
  const currentFileNames = currentFiles.map(item => item.name);
  // 重複したファイル名やフォルダ名をsetに保存
  const duplicatedFolderNameSet = new Set();
  const duplicatedFileNameSet = new Set();
  notDcmFiles
    .map(item => item.path.normalize(normalizeForm)) // macOSではNFDになっているのでNFCにする
    .forEach(path => {
      let pathList = [];
      if (path[0] == '/') {
        pathList = path.slice(1).split('/');
      } else {
        pathList = path.split('/');
      }
      if (pathList.length >= 2) {
        //folder
        const topFolderName = pathList[0];
        if (currentFolderNames.includes(topFolderName)) {
          duplicatedFolderNameSet.add(topFolderName);
        }
      } else {
        // file
        const fileName = pathList[0];
        if (currentFileNames.includes(fileName)) {
          duplicatedFileNameSet.add(fileName);
        }
      }
    });

  // setを確認して実際に重複していたものを取得
  duplicatedFolders = currentFolders.filter(item => {
    return duplicatedFolderNameSet.has(item.name);
  });
  duplicatedFiles = currentFiles.filter(item => {
    return duplicatedFileNameSet.has(item.name);
  });

  return [duplicatedStudies, duplicatedFolders, duplicatedFiles];
};
